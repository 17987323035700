import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

import { SERVER_URL } from "../../config";

const BASE_URL = SERVER_URL;
const ClassHeader = () => {
  // const { id } = useParams();
  const location = useLocation();
  const id = location.state?.classId; // Access the class ID from state


  const [className, setClassName] = useState('');

  // useEffect(() => {

  //   const postData = {
  //     class: id
  //   };


  //   axios.post(`${BASE_URL}/class-details`, postData)
  //     .then(response => {
  //       // Handle the response if needed
  //       // console.log('POST request successful: ', response.data.data.name);
  //       setClassName(response.data.data.name);
  //     })
  //     .catch(error => {
  //       // Handle errors
  //       console.error('Error making POST request: ', error);
  //     });

  //   // Ensure to add any dependencies to the dependency array if needed
  // }, []);

  const postClassDetails = async (retryDelay = 1000) => {
    const postData = { class: id };
  
    try {
      const response = await axios.post(`${BASE_URL}/class-details`, postData);
      setClassName(response.data.data.name);
    } catch (error) {
      if (error.response?.status === 429) {
        console.warn(`429 Too Many Requests. Retrying in ${retryDelay / 1000} seconds...`);
        setTimeout(() => {
          postClassDetails(retryDelay * 2); // Exponential backoff
        }, retryDelay);
      } else {
        console.error('Error making POST request:', error);
      }
    }
  };
  
  useEffect(() => {
    postClassDetails();
  }, [id]); // Add `id` if it needs to trigger this useEffect when updated
  
  return (
    <>
      <section className="inner-banner about-banner position-relative">
        <div className="position-absolute bottom-0 end-0 z-1 w-100 h-100 gradient__overlay bg-dark opacity-50"></div>
        <div className="container position-relative z-3">
          <h1 className="display-3 fw-bold text-white text-left">
            <span className="fs-3 fw-normal d-block">Simply Powerful online</span>
            {className}
          </h1>
        </div>
      </section>

    </>
  )
}
export default ClassHeader