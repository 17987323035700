import React, { useState, useEffect } from 'react';
import Header from '../layouts/Header/Header';
import Sidebar from '../layouts/Sidebar/Sidebar';
import './QuizPage.css';
import axios from 'axios';
import { SERVER_URL } from "../../config";
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';



function QuizPage() {


  const [quizzes, setQuizzes] = useState([]);
  const [studentDetail, setStudentDetail] = useState('');
  const [selectedChapter, setSelectedChapter] = useState(''); //display subject data
  const [selectedSubject, setSelectedSubject] = useState('all');  // set search subject
  const [searchChapter, setSearchChapter] = useState('all'); // search chapter
  const [chapters, setChapters] = useState('');  //display chapter  data
  const [filterSearch, setFilterSearch] = useState([]);

  const [error, setError] = useState('');

  const BASE_URL = SERVER_URL;



  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    axios.get(`${BASE_URL}/quizes`, {
      headers: {
        'remember-token': token,
      }
    })
      .then(response => {
        if (response.data.status) {
          setQuizzes(response.data.data);
          setError('');
        }
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError('No Data Found.');
      });
  }, []);

  //get student details

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.get(`${BASE_URL}/student-details`, {
      headers: {
        'remember-token': token,
      }
    })
      .then(response => {
        if (response.data) {
          setStudentDetail(response.data.data.class_id);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError('No Data Found.');
      });
  }, []);


  const subjectData = async (studentDetail) => {
    const token = localStorage.getItem('token');

    await axios.post(`${BASE_URL}/subjects`, { class: studentDetail }, {
      headers: {
        'remember-token': token,
      }
    })
      .then(response => {
        if (response.data) {
          setSelectedChapter(response.data.data);
          selectedSubject(studentDetail);
          // console.log("subjected data", response.data.data);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError('No Data Found.');
      });
  }
  //subject subject chapter 
  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
    let subject_id = e.target.value;
    const token = localStorage.getItem('token');

    axios.post(`${BASE_URL}/subject-chapters`, {
      "subject": subject_id,
      "class": studentDetail
    })
      .then(response => {
        if (response.data) {
          setChapters(response.data.data);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError('No Data Found.');
      });
  };




  // console.log(selectedChapter);

  //get all subject list
  useEffect(() => {
    subjectData(studentDetail);
  }, [studentDetail]);

  //search Quiz Data 
  const SearchQuizData = async () => {
    const token = localStorage.getItem('token');
    const postData = {
      subject_id: selectedSubject,
      chapter_id: searchChapter,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}/quizes`,
        postData,
        {
          headers: {
            'remember-token': token,
          }
        }
      );
      // console.log("search data", response.data.data);
      //  setFilterSearch(response.data.data);
      setQuizzes(response.data.data);
    }
    catch (error) {
      console.error('There was an error fetching the data!', error);
      setError('Search failed.');
    }
  }


  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const quizzesPerPage = 10;
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  // entries filter
  const handleEntriesChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value));
    setCurrentPage(0);
  };
  //search filter
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0);
  };



  const offset = currentPage * entriesPerPage;
  const query = searchQuery.toLowerCase();

  const filteredQuizzes = quizzes.filter(quiz =>
    quiz.class_name.toLowerCase().includes(query) ||
    quiz.subject_name.toLowerCase().includes(query) ||
    (quiz.chapter && quiz.chapter.toLowerCase().includes(query)) ||
    quiz.difficulty_level_name.toLowerCase().includes(query) ||
    quiz.duration.toString().includes(query)
  );
  const currentQuizzes = filteredQuizzes.slice(offset, offset + entriesPerPage);

  const navigate = useNavigate();
  const handleButtonClick = (id) => {
    navigate(`/quize-details/${id}`);
  };

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      <div className="quiz__page">
        <div className='row g-4'>
          <div className="col-xl-12 quiz-table-container">
            <div className="d-flex align-item-center justify-content-between">
              <h1 className='fs-3 text-blue fw-medium'>Quiz</h1>
              <div className='text-muted'>Dashboard <span>/ Quiz</span></div>
            </div>

            <div className="border-top border-bottom py-3 mb-4"> 
              <div className="row g-3">
                <div className="col-md-5">
                  <select className="form-select" value={selectedSubject} onChange={handleSubjectChange} >
                    <option value="all">Select Subject</option>
                    {selectedChapter && selectedChapter.length > 0 ?
                      (
                        selectedChapter.map((subject, index) => (
                          <option key={index} value={subject.subject_id} selected={selectedSubject === subject.subject_id}>{subject.subject}</option>
                        ))
                      ) :
                      (
                        <p>Options not available</p>
                      )}

                  </select> 
                </div>
                <div className="col-md-5"> 
                  <select className="form-select" value={searchChapter} onChange={(e) => setSearchChapter(e.target.value)} >
                    <option value="all">Select Chapter</option>

                    {chapters && chapters.length > 0 ?
                      (
                        chapters.map((chapter, index) => (
                          <option key={index} value={chapter.id} selected={searchChapter === chapter.id}>{chapter.chapter}</option>
                        ))
                      ) :
                      (
                        <p>Options not available</p>
                      )}

                  </select>
                </div>
                <div className="col-md-2"> 
                  <button className="btn btn-primary blue btn-sm w-100 py-3" onClick={SearchQuizData}>Search</button>
                </div>
              </div>
            </div>
            <div className='quiz-entry-main-student-container'>
              <div className="quiz-entry-student-container">
                <div className="row g-3 justify-content-between filter__box mb-3">
                  <div className="col-sm-5">
                    <div className="d-flex gap-2 align-items-center">
                      <p className='m-0'>Show</p>
                      <select className='form-select' onChange={handleEntriesChange} value={entriesPerPage}>
                        <option value="10">10 Entries</option>
                        <option value="50">50 Entries</option>
                        <option value="100">100 Entries</option>
                      </select>
                    </div> 
                  </div>
                  <div className="col-sm-5 col-lg-4"> 
                    <input type="search" placeholder="Search" value={searchQuery} onChange={handleSearchChange} className='form-control' />
                  </div>
                </div>
                <div className="table-responsive"> 
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Class</th>
                        <th>Subject</th>
                        <th>Chapter</th>
                        <th>Difficulty Level</th>
                        <th>Duration</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentQuizzes && currentQuizzes.length > 0 ? (
                        currentQuizzes.map((quiz, index) => (
                          <tr key={quiz.id}>
                            <td className="align-middle">{offset + index + 1}</td>
                            <td className="align-middle">{quiz.class_name}</td>
                            <td className="align-middle">{quiz.subject_name}</td>
                            <td className="align-middle">{quiz.chapter ? quiz.chapter : 'N/A'}</td>
                            <td className="align-middle">{quiz.difficulty_level_name}</td>
                            <td className="align-middle">{quiz.duration}</td>
                            <td>
                              <button className="btn btn-secondary btn-sm py-2 px-3" onClick={() => handleButtonClick(quiz.id)}>View Result</button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7} style={{ textAlign: "center" }}>No data available</td>
                        </tr>

                      )}

                    </tbody>

                  </table>
                </div>

                <div>
                  <p>Total Rows: {quizzes.length}</p>
                </div>
                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={Math.ceil(quizzes.length / entriesPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
              {/* <div className="quizz-entries-details">
                  <p className='mt-4'>Showing {currentPageIndex + 1} of {quizzes.length}</p>
                  <div className="d-flex align-item-center mt-4">
                    <button className="btn border-0 mt-0" onClick={goToPrevious} disabled={currentPageIndex === 0}>Previous</button>
                    <button className="btn border-0 mt-0"  onClick={goToNext} disabled={currentPageIndex === quizzes.length - 1}>Next</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
         
      </div>
    </>
  );
}

export default QuizPage;
