import "./ExamQuestion.css";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addResultReducer, getResultReducer, quizQuestionsReducer, finalSubmitReducer, quizResultReducer, resultReducer, startExamReducer, quizAnswerReducer, quizAnswerStatusReducer } from "./ExamSlice.jsx"
import PageRefreshWarning from "./PageRefreshWarning.jsx";
import { useNavigate } from 'react-router-dom';
import useBlockNavigation from "./BlockNavigation.jsx";

import { SERVER_URL , IMAGE_URL} from "../../config/index.js";
import axios from "axios";
const BASE_URL = SERVER_URL;
const IMAGE_BASE_URL = IMAGE_URL
function ExamPage({ id, subjectId, ChapterId, timeLimit, Difficulty, no_of_question,sub_type,stream_id,  quizData, startExam, className }) {
  const dispatch = useDispatch();


  const navigate = useNavigate();


  let stateResponse = useSelector((state) => state)

  let [examDetails, setExamDetails] = useState(startExam?.data)

  let [quizStatus, setQuizStatus] = useState([]);

  // Count the number of times 4,3,2,1 appears in the quizStatus array
  const answeredCount = quizStatus.filter(status => status === 1).length;
  const notAnsweredCount = quizStatus.filter(status => status === 2).length;
  const markedCount = quizStatus.filter(status => status === 3).length;
  const notVisitedCount = quizStatus.filter(status => status === 4).length;


  const [time_limit, setTimeLimit] = useState(examDetails?.duration);

  useEffect(() => {
    if (time_limit !== undefined) {
      setTimeLeft(time_limit * 60);
    }
  }, [time_limit]);


  const autoSubmitDelay = 30000; // auto-submit delay in milliseconds (30 seconds)

  const [currentQuestion, setCurrentQuestion] = useState(0);

  let filtered_Data = quizData.filter(item => item.is_on_review === 1);

  const [answers, setAnswers] = useState({});

  const [reviewQuestions, setReviewQuestions] = useState([]);

  // const [timeLeft, setTimeLeft] = useState(time);
  const [timeLeft, setTimeLeft] = useState(time_limit * 60);
  const [submitted, setSubmitted] = useState(false);
  const [questionStatus, setQuestionStatus] = useState(
    quizData.map(() => '4')
  );
  const [currentTime, setCurrentTime] = useState('');

  // handle 429 too many request issue by using Request Queue
  class RequestQueue {
    constructor() {
        this.queue = [];
        this.isProcessing = false;
    }
  
    enqueue(requestFunction) {
        this.queue.push(requestFunction);
        this.processQueue();
    }
  
    async processQueue() {
        if (this.isProcessing) return;
  
        this.isProcessing = true;
  
        while (this.queue.length > 0) {
            const requestFunction = this.queue.shift();
            await requestFunction();
        }
  
        this.isProcessing = false;
    }
  }
  
  const requestQueue = new RequestQueue();

  // const initiateExam = async () => {
  //   try {
  //     const token = await localStorage.getItem('token');
  //     const response = await axios.post(`${BASE_URL}/start-exam`, {
  //       class: id,
  //       subject: subjectId,
  //       chapter: ChapterId,
  //       difficulty_level: Difficulty,
  //       no_of_question: no_of_question,
  //       duration: time_limit
  //     }, {
  //       headers: {
  //         'remember-token': token,
  //       }
  //     });

  //     setExamDetails(response?.data?.data);
  //     setTimeLimit(response?.data?.data?.duration)
  //     return response.data;
  //   } catch (error) {
  //     throw new Error(error.response?.data?.message || error.message);
  //   }
  // }



const initiateExam = async () => {
  return new Promise((resolve, reject) => {
      requestQueue.enqueue(async () => {
          try {
              const token = await localStorage.getItem('token');
              const response = await axios.post(`${BASE_URL}/start-exam`, {
                  class: id,
                  subject: subjectId,
                  chapter: ChapterId,
                  difficulty_level: Difficulty,
                  no_of_question: no_of_question,
                  duration: time_limit,
                  sub_type: sub_type,
                  stream_id: stream_id
              }, {
                  headers: {
                      'remember-token': token,
                  }
              });

              setExamDetails(response?.data?.data);
              setTimeLimit(response?.data?.data?.duration);
              resolve(response.data);
          } catch (error) {
              if (error.response?.status === 429) {
                  console.warn('429 Too Many Requests. Retrying in 1 second...');
                  setTimeout(() => {
                      initiateExam().then(resolve).catch(reject);
                  }, 1000);
              } else {
                  reject(new Error(error.response?.data?.message || error.message));
              }
          }
      });
  });
};

  useEffect(() => {

    const interval = setInterval(() => {
      const now = new Date();

      const formattedTime = formatDate(now);

      setCurrentTime(formattedTime);

    }, 1000);
    return () => clearInterval(interval);

  }, []);


  const formatDate = (date) => {
    const padZero = (num) => (num < 10 ? `0${num}` : num);

    const day = padZero(date.getDate());
    const month = padZero(date.getMonth() + 1); // Months are zero-indexed
    const year = date.getFullYear();
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };




  const getQuizResult = useCallback(async () => {
    try {

      let params = {};
      if (examDetails != null) {
        params.quiz_id = examDetails.id;
      }
      dispatch(quizResultReducer(params));
    } catch (error) {
      console.error("Failed to fetch quiz questions:", error);
    }
  }, [examDetails]);

  const getResult = useCallback(async () => {
    try {

      let params = {};

      if (examDetails != null) {
        params.quiz_id = examDetails.id;
      }
      dispatch(resultReducer(params));
    } catch (error) {
      console.error("Failed to fetch quiz questions:", error);
    }
  }, [examDetails]);


  const examSubmit = useCallback(async () => {
    try {

      let params = {};

      if (examDetails != null) {
        params.quiz_id = examDetails.id;
        params.end_time = currentTime;
        params.status = 1;
      }
      dispatch(finalSubmitReducer(params));

    } catch (error) {
      console.error("Failed to fetch quiz questions:", error);
    }
  }, [examDetails, currentTime]);

  const [startTime, setStartTime] = useState(Date.now());

  // const handleNextAddResult = async (question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken) => {
  //   try {
  //     // console.log("ncnc", "resultId",result_id, "examDetails.id",examDetails.id, "QuestionId", question_Id, "selectedOption",selectedOption, "isReview",isReview, "questionStatusId", questionStatusId);

  //     const token = await localStorage.getItem('token');
  //     let params = {};

  //     if (examDetails != null) {
  //       params.id = result_id;
  //       params.quiz_id = examDetails.id;
  //       params.question_id = question_Id;
  //       params.correct = selectedOption;
  //       params.is_review = isReview;
  //       params.question_status = questionStatusId;
  //       params.duration = timeTaken;
  //     }
  //     const response = await axios.post(`${BASE_URL}/add-result`, params, {
  //       headers: {
  //         'remember-token': token,
  //         'Content-Type': 'application/json'
  //       }
  //     });

  //     if (response && response.data) {
  //       setQuizStatus(response?.data?.question);
  //       setAnswers(response?.data?.answer);
  //       setReviewQuestions(response?.data?.is_on_review)
  //     }

  //     return response.data;
  //   } catch (error) {
  //     throw new Error(error.response?.data?.message || error.message);
  //   }
  // };




const handleNextAddResult = async (question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken) => {
  return new Promise((resolve, reject) => {
      requestQueue.enqueue(async () => {
          try {
              const token = await localStorage.getItem('token');
              let params = {};

              if (examDetails != null) {
                  params.id = result_id;
                  params.quiz_id = examDetails.id;
                  params.question_id = question_Id;
                  params.correct = selectedOption;
                  params.is_review = isReview;
                  params.question_status = questionStatusId;
                  params.duration = timeTaken;
              }

              const response = await axios.post(`${BASE_URL}/add-result`, params, {
                  headers: {
                      'remember-token': token,
                      'Content-Type': 'application/json'
                  }
              });

              if (response && response.data) {
                  setQuizStatus(response.data.question);
                  setAnswers(response.data.answer);
                  setReviewQuestions(response.data.is_on_review);
              }

              resolve(response.data);
          } catch (error) {
              if (error.response?.status === 429) {
                  console.warn('429 Too Many Requests. Retrying in 1 second...');
                  setTimeout(() => {
                      handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken)
                          .then(resolve)
                          .catch(reject);
                  }, 1000);
              } else {
                  reject(new Error(error.response?.data?.message || error.message));
              }
          }
      });
  });
};



  const handleAnswer = (question_Id, selectedOption, result_id) => {

    if (reviewQuestions.includes(question_Id)) {
      setReviewQuestions(prevReviews =>
        prevReviews.filter(id => id !== question_Id)
      );

      const updatedStatus = [...quizStatus];
      updatedStatus[currentQuestion] = '1';
      setQuizStatus(updatedStatus);

      let questionStatusId = "1";
      let isReview = "1";
      // handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview);
      const calculateDuration = () => {

        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

        let timeTaken = duration.toFixed(2);

        handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken);

      };


      // When the component mounts and currentQuestion changes
      if (currentQuestion + 1 !== 0) { // Assuming the first question does not require previous question's time calculation
        calculateDuration();
      }

      // Set new start time for the next question
      setStartTime(Date.now());

      setAnswers(prevAnswers => ({
        ...prevAnswers,
        [question_Id]: selectedOption
      }));
    } else {

      const updatedStatus = [...quizStatus];
      updatedStatus[currentQuestion] = '1';
      setQuizStatus(updatedStatus);

      let questionStatusId = "1";
      let isReview = "0";
      // handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview);
      const calculateDuration = () => {

        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

        let timeTaken = duration.toFixed(2);

        handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken);

      };


      // When the component mounts and currentQuestion changes
      if (currentQuestion + 1 !== 0) { // Assuming the first question does not require previous question's time calculation
        calculateDuration();
      }

      // Set new start time for the next question
      setStartTime(Date.now());

      setAnswers(prevAnswers => ({
        ...prevAnswers,
        [question_Id]: selectedOption
      }));
    }

  };

  const handleNextAnswer = (question_Id, selectedOption, result_id) => {


    if (answers[quizData[currentQuestion].id] === '') {


      if (reviewQuestions.includes(question_Id)) {
        setReviewQuestions(prevReviews =>
          prevReviews.filter(id => id !== question_Id)
        );

        const updatedStatus = [...quizStatus];
        updatedStatus[currentQuestion] = '2';
        setQuizStatus(updatedStatus);
        let questionStatusId = "2";
        let isReview = "0";
        // handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId,isReview);
        const calculateDuration = () => {

          const endTime = Date.now();
          const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

          let timeTaken = duration.toFixed(2);

          handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken);

        };


        // When the component mounts and currentQuestion changes
        if (currentQuestion + 1 !== 0) { // Assuming the first question does not require previous question's time calculation
          calculateDuration();
        }

        // Set new start time for the next question
        setStartTime(Date.now());
      } else {

        const updatedStatus = [...quizStatus];
        updatedStatus[currentQuestion] = '2';
        setQuizStatus(updatedStatus);
        let questionStatusId = "2";
        let isReview = "0"
        // handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId,isReview);.
        const calculateDuration = () => {

          const endTime = Date.now();
          const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

          let timeTaken = duration.toFixed(2);

          handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken);

        };


        // When the component mounts and currentQuestion changes
        if (currentQuestion + 1 !== 0) { // Assuming the first question does not require previous question's time calculation
          calculateDuration();
        }

        // Set new start time for the next question
        setStartTime(Date.now());
      }


    }

    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [question_Id]: selectedOption
    }));
  }

  const handleClearAnswer = (question_Id, selectedOption, result_id) => {

    if (reviewQuestions.includes(question_Id)) {
      setReviewQuestions(prevReviews =>
        prevReviews.filter(id => id !== question_Id)
      );
      const updatedStatus = [...quizStatus];
      updatedStatus[currentQuestion] = '2';
      setQuizStatus(updatedStatus);

      let questionStatusId = "2";
      let isReview = "0";
      // handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview);
      const calculateDuration = () => {

        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

        let timeTaken = duration.toFixed(2);

        handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken);

      };


      // When the component mounts and currentQuestion changes
      if (currentQuestion + 1 !== 0) { // Assuming the first question does not require previous question's time calculation
        calculateDuration();
      }

      // Set new start time for the next question
      setStartTime(Date.now());

      const updatedAnswers = { ...answers };
      delete updatedAnswers[quizData[currentQuestion]?.id];
      setAnswers(updatedAnswers);
    } else {
      const updatedStatus = [...quizStatus];
      updatedStatus[currentQuestion] = '2';
      setQuizStatus(updatedStatus);

      let questionStatusId = "2";
      let isReview = "0";
      // handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview);
      const calculateDuration = () => {

        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

        let timeTaken = duration.toFixed(2);

        handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken);

      };


      // When the component mounts and currentQuestion changes
      if (currentQuestion + 1 !== 0) { // Assuming the first question does not require previous question's time calculation
        calculateDuration();
      }

      // Set new start time for the next question
      setStartTime(Date.now());

      const updatedAnswers = { ...answers };
      delete updatedAnswers[quizData[currentQuestion]?.id];
      setAnswers(updatedAnswers);
    }

    // setQuestionStatusId("2");
  };

  // const handleReview = (question_Id, selectedOption, result_id) => {

  //       if (quizData[currentQuestion].is_on_review === 1) {

  //     const updatedStatus = [...quizStatus];
  //     updatedStatus[currentQuestion] = quizData[currentQuestion].is_on_review === 0 ? '1' : '2';
  //     setQuizStatus(updatedStatus);


  //     let questionStatusId=  quizData[currentQuestion].is_on_review === 0 ? '1' : '2';
  //     handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId);
  //   } else {
  //     const updatedStatus = [...questionStatus];
  //     updatedStatus[currentQuestion] = '3';
  //     setQuizStatus(updatedStatus);
  //     let questionStatusId = '3';
  //     handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId);
  //   }
  // };


  const handleReview = (question_Id, selectedOption, result_id) => {
    if (reviewQuestions.includes(question_Id)) {
      setReviewQuestions(prevReviews =>
        prevReviews.filter(id => id !== question_Id)
      );
      const updatedStatus = [...questionStatus];
      updatedStatus[currentQuestion] = answers[question_Id] ? '1' : '2';
      setQuestionStatus(updatedStatus);


      let questionStatusId = answers[question_Id] ? '1' : '2';
      // setQuestionStatusId(updatedStatusId);
      let isReview = "0";
      // handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview);
      const calculateDuration = () => {

        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

        let timeTaken = duration.toFixed(2);

        handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken);

      };


      // When the component mounts and currentQuestion changes
      if (currentQuestion + 1 !== 0) { // Assuming the first question does not require previous question's time calculation
        calculateDuration();
      }

      // Set new start time for the next question
      setStartTime(Date.now());
    } else {
      setReviewQuestions(prevReviews => [...prevReviews, question_Id]);
      const updatedStatus = [...questionStatus];
      updatedStatus[currentQuestion] = '3';
      setQuestionStatus(updatedStatus);

      let questionStatusId = '3';
      let isReview = "1";
      // handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId,isReview);
      const calculateDuration = () => {

        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

        let timeTaken = duration.toFixed(2);

        handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken);

      };


      // When the component mounts and currentQuestion changes
      if (currentQuestion + 1 !== 0) { // Assuming the first question does not require previous question's time calculation
        calculateDuration();
      }

      // Set new start time for the next question
      setStartTime(Date.now());
    }
  };


  const handleNavigation = (direction) => {
    // setIsReview(0); 
    if (direction === 'next' && currentQuestion < quizData.length - 1) {
      setCurrentQuestion(prevQuestion => prevQuestion + 1);
    } else if (direction === 'prev' && currentQuestion > 0) {
      setCurrentQuestion(prevQuestion => prevQuestion - 1);
    }
  };

  const handlePaletteClick = (index) => {
    // setIsReview(0); 
    setCurrentQuestion(index);
  };




  const handleSubmit = async () => {

    const confirmed = window.confirm("Are you sure you want to submit?");
    if (confirmed) {
      setSubmitted(true);
      examSubmit();
      getQuizResult();
      getResult();
    }else{
      setSubmitted(false);
    }
  }

  const handleTimerSubmit = async () => {
    alert("Your exam time is over!");
    setSubmitted(true);
    examSubmit();
    getQuizResult();
    getResult();
  }


  const handleInstruction = () => {
    navigate('/instruction', {
      state: { id, subjectId, ChapterId, timeLimit, Difficulty, no_of_question,sub_type,stream_id ,className }
    });
  }

  const handleQuiz = () => {
    navigate('/quiz');
  }

  const handleProfile = () => {
    navigate('/profile');
  }
  const buttonStyle = {
    background: currentQuestion === 0 ? 'rgba(167, 205, 217, 1)' : 'rgba(0, 164, 216, 1)'
  };


  if (examDetails != null) {
    const quiz_id = examDetails.id;
    if (submitted === true) {
      navigate('/result', {
        state: { quiz_id }
      });
    }
  }

  //   useEffect(() => {
  //     const calculateDuration = () => {

  //       const endTime = Date.now();
  //       const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

  //       timeTaken = duration.toFixed(2);
  //   };


  //   // When the component mounts and currentQuestion changes
  //   if (currentQuestion+1 !== 0) { // Assuming the first question does not require previous question's time calculation
  //       calculateDuration();
  //   }

  //   // Set new start time for the next question
  //   setStartTime(Date.now());


  // }, [currentQuestion]); 


  useEffect(() => {


    if (examDetails != null) {
      let question_Id = quizData[currentQuestion].id;
      let selectedOption = quizData[currentQuestion].answer;
      let result_id = quizData[currentQuestion].result_id;
      let questionStatusId = quizData[currentQuestion].question_status;
      let isReview = quizData[currentQuestion].is_on_review;

      const calculateDuration = () => {

        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000; // convert milliseconds to seconds

        let timeTaken = duration.toFixed(2);

        handleNextAddResult(question_Id, selectedOption, result_id, questionStatusId, isReview, timeTaken);

      };


      // When the component mounts and currentQuestion changes
      if (currentQuestion + 1 !== 0) { // Assuming the first question does not require previous question's time calculation
        calculateDuration();
      }

      // Set new start time for the next question
      setStartTime(Date.now());



      // setTimeLimit(examDetails?.duration);

    }
  }, [examDetails])



  useEffect(() => {
    initiateExam();

    setReviewQuestions(filtered_Data);

  }, [stateResponse.quizQuestions.startExam])





  useEffect(() => {
    let timer;
    if (timeLeft > 0 && !submitted) {
      timer = setTimeout(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0 && !submitted) {
      handleTimerSubmit();
    }
    return () => clearTimeout(timer);
  }, [timeLeft, submitted]);



  useEffect(() => {
    let autoSubmitTimer;
    const checkNetworkStatus = () => {
      try {
        if (!navigator.onLine) {
          autoSubmitTimer = setTimeout(() => {
            if (!submitted) {
              handleSubmit();
            }
          }, autoSubmitDelay);
        } else {
          clearTimeout(autoSubmitTimer);
        }
      } catch (error) {
        console.error("Error checking network status:", error);
      }
    };
    checkNetworkStatus();

    const interval = setInterval(checkNetworkStatus, 5000);

    return () => clearInterval(interval);
  }, [submitted, autoSubmitDelay]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [profile, setProfile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [profilePicName, setProfilePicName] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    axios.get(`${BASE_URL}/student-details`, {
      headers: {
        'remember-token': token,
      }
    })
      .then(response => {

        if (response.data.status) {

          setProfile(response.data.data.user_name);
          setPreviewUrl(response.data.data.profile);

          let profile = response.data.data.profile;
          let imageName = profile.split('/').pop();
          setProfilePicName(imageName);
          setError(''); // Clear any previous errors
        }
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError('No Data Found.');
      });
  }, []);
  return (
    <>
      <div className="exam-question-top-container p-4">
        <div className="row g-4">

          <div className="col-xl-8 col-lg-12 exam-question-main-container">
            <div className="d-flex gap-3 mb-4">
              {quizData[currentQuestion] ? (
                <>
                  {/* <button className="btn btn-primary blue rounded-0">Verbal Ability</button> */}
                  {/* <button className="btn btn-primary blue rounded-0" disabled>Analytical Ability</button> */}
                  <button className="btn btn-primary blue rounded-0">Descriptive</button>

                </>
              ) : (
                <p>Question not available</p>
              )}
            </div>
            <div className="col-lg-12 scroller">
              <h5 className="lead-sm fw-medium text-dark">Sections</h5>
              <div className="exam-question-type-container">
                {/* {quizData[currentQuestion] ? (

                  <p>Question Type: {quizData[currentQuestion].type + ' Ability'}</p>
                ) : (
                  <p>Question not available</p>
                )} */}
                <p>Question Type: Descriptive</p>
              </div>
              <div className="question__content__box">
                  <div className="col-lg-10">

                    <h5 className="lead-sm fw-medium text-dark">Question No {currentQuestion + 1}. </h5>
                    <hr />
                    {quizData[currentQuestion] ? (
                      <>

                        {/* <p className="exam-question-paragraph">{quizData[currentQuestion].question}</p> */}
                        {/* <p className="exam-question-paragraph" dangerouslySetInnerHTML={{ __html: quizData[currentQuestion].question.replace( /<img([^>]+)>/g,
                                  '<img$1 class="img-resize" />'
                                ),
                              }}
                            /> */}
                        {
                          quizData[currentQuestion].question_option === 1 ? (
                            <p
                              className="exam-question-paragraph"
                              dangerouslySetInnerHTML={{
                                __html: quizData[currentQuestion].question.replace(
                                  /<img([^>]+)>/g,
                                  '<img$1 class="img-resize" />'
                                ),
                              }}
                            />
                          ) : (
                            <p className="exam-question-paragraph">
                              <img
                                src={`${IMAGE_BASE_URL + quizData[currentQuestion].question}`}
                                alt="Question Preview"
                                style={{ width: '350px', height: '350px', objectFit: 'contain' }}
                                className="img-thumbnail mb-2"
                              />
                            </p>
                          )
                        }

                        {/* {quizData[currentQuestion].image && (
                          <img src={quizData[currentQuestion].image} alt="Question Preview"
                            style={{ width: 'auto', height: '200px', objectFit: 'contain' }}
                            className="img-thumbnail mb-2"
                          />
                        )} */}


                      </>
                    ) : (
                      <p className="exam-question-paragraph">Question not available</p>
                    )}
                  </div>

                  <div className={'options'}>
                    {quizData[currentQuestion] && quizData[currentQuestion].options ? (
                      quizData[currentQuestion].options.map((option, index) => (
                        <div key={index}>
                          <input
                            type="radio" style={{ "marginRight": "20px" }}
                            id={`question-${quizData[currentQuestion].id}-option-${index}`}
                            name={`question-${quizData[currentQuestion].id}-option-${index}`}
                            value={`option${index + 1}`}
                            checked={answers[quizData[currentQuestion].id] === `option${index + 1}`}    // || quizData[currentQuestion].answer === `option${index}`
                            onChange={() => {
                              handleAnswer(quizData[currentQuestion].id, `option${index + 1}`, quizData[currentQuestion].result_id);
                            }}
                            className="form-check-input"
                          />
                          {/* <label className="form-check-label" htmlFor={`question-${quizData[currentQuestion].id}-option-${index}`} >{option}</label> */}
                          {/* <label className="form-check-label" htmlFor={`question-${quizData[currentQuestion].id}-option-${index}`}  dangerouslySetInnerHTML={{ __html: option.replace(/<img([^>]+)>/g, '<img$1 class="img-setting" />') }} /> */}
                          {
                            quizData[currentQuestion][`option_type${index + 1}`] === 2 ? (
                              <img
                                src={IMAGE_BASE_URL + quizData[currentQuestion].options[index]}
                                alt={`Option ${index}`}
                                className="img-setting"
                                style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                              />
                            ) : (
                              <label
                                className="form-check-label"
                                htmlFor={`question-${quizData[currentQuestion].id}-option-${index}`}
                                dangerouslySetInnerHTML={{
                                  __html: quizData[currentQuestion].options[index].replace(
                                    /<img([^>]+)>/g,
                                    '<img$1 class="img-setting" />'
                                  ),
                                }}
                              />
                            )
                          }



                        </div>
                      ))
                    ) : (
                      <p className="text-danger mb-0 px-2 py-3 rounded-2 text-center" style={{ backgroundColor: '#ff000026' }}>Options not available</p>
                    )}
                  </div>

              </div>
              {/* question__content__box */}


              <div className="mt-5 mb-4 d-flex gap-3 flex-wrap justify-content-between exam__btns__box">
                <div className="d-flex gap-3"> 
                  {quizData[currentQuestion] ? (
                    <button
                      className="btn btn-primary blue"
                      onClick={() => {
                        handleReview(quizData[currentQuestion].id, answers[quizData[currentQuestion].id], quizData[currentQuestion].result_id);
                        // reviewQuestions.includes(quizData[currentQuestion].id) ? setIsReview(1) : setIsReview(0);
                      }}
                    >
                      {reviewQuestions.includes(quizData[currentQuestion].id) ? 'Unmark Review' : 'Mark For Review'}
                      {/* {quizData[currentQuestion].is_on_review ? 'Unmark Review' : 'Mark For Review'} */}
                    </button>
                  ) : (
                    <p className="text-danger mb-0 px-2 py-1 rounded-2 d-inline-flex align-items-center" style={{ backgroundColor: '#ff000026' }}>Question not available</p>
                  )}
                  <button className="btn btn-secondary" onClick={() => {
                    handleClearAnswer(quizData[currentQuestion].id, ``, quizData[currentQuestion].result_id);
                  }}>Clear Response</button>
                </div>


                <div className="d-flex gap-3">
                  <button className="btn btn-primary blue" onClick={() => {
                    handleNavigation('prev');
                    handleNextAnswer(quizData[currentQuestion].id, answers[quizData[currentQuestion].id], quizData[currentQuestion].result_id);
                  }} disabled={currentQuestion === 0} style={buttonStyle}>
                    Previous
                  </button>
                  <button className="btn btn-primary blue"
                    onClick={() => {
                      handleNavigation('next');
                      handleNextAnswer(quizData[currentQuestion].id, answers[quizData[currentQuestion].id], quizData[currentQuestion].result_id);
                    }} disabled={currentQuestion === quizData.length - 1}
                    style={{ background: currentQuestion === quizData.length - 1 ? 'rgba(167, 205, 217, 1)' : 'rgba(0, 164, 216, 1)' }}
                  >
                    Next
                  </button>
                </div>

 
               
              </div>
            </div>
            {/* col-12 */}
          </div>


          <div className=" col-xl-4 col-lg-6 exam-question-bottom-container">
             
            <div className="exam-question-timer-container p-3 d-flex align-items-center">
              <div className="exam-question-profile-container p-0 overflow-hidden">
                {previewUrl && (
                  <img src={previewUrl} alt="Profile Preview" 
                    className="img-fluid"
                  />
                )}
              </div>
              <div className="exam-question-timer">
                <h2 className="fs-4 text-white fw-medium mb-0">Time Left: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60}</h2>
                <p className="text-white mb-0">Candidate : {profile ?? " "}</p>
              </div>
            </div>

            <div className="exam-question-bottom-1-container p-3 mt-4 rounded-3">
              <div className="exam-question-attempted-details mt-4">
                {/* <h1>You are viewing Verbal Ability Section</h1> */}
                {/* <hr/> */}
                <p>Question Palette:</p>
                <div className="exam-question-attempted-question-details-container">
                  {/* <div className="cont-1">
                                        <p>1</p>
                                    </div> */}
                  {quizData.map((_, index) => {
                    const status = quizStatus[index] ? quizStatus[index] : '4';

                    return (
                      <p
                        key={index}
                        className={`cont-${status} m-0 d-inline-flex align-items-center justify-content-center`}
                        onClick={() => { handlePaletteClick(index) }}
                      >
                        {index + 1}
                      </p>
                    );
                  })}
                </div>
              </div>
              
              <p className="lead fw-medium text-dark mb-2 legend__title">Legend:</p>
              <div className="exam-question-result-container col-lg-12">
                <div className="d-flex gap-3 align-item-center col-6">
                  <div className="result-1"></div>
           
                  <p className="m-0">Answered {answeredCount ?? 0}</p>
                </div>
                <div className="d-flex gap-3 align-item-center col-6">
                  <div className="result-2"></div>
                  <p className="m-0">Not Answered {notAnsweredCount ?? 0}</p>
                </div>
                <div className="d-flex gap-3 align-item-center col-6">
                  <div className="result-3"></div>
                  <p className="m-0">Marked {markedCount ?? 0}</p>
                </div>
                <div className="d-flex gap-3 align-item-center col-6">
                  <div className="result-4"></div>
                  <p className="m-0">Not Visited {notVisitedCount ?? 0}</p>
                </div>
              </div>

              <div className="exam-question-button-one-container mt-4">
                <div className="row gx-3 gy-4">
                  {/* <div className="d-flex align-item-center col-6">
                    <button className="button-one" onClick={handleProfile}>Profile</button>
                  </div> */}
                  <div className="d-flex align-item-center col-6">
                    <button className="button-one" onClick={handleInstruction}>Instructions</button>
                  </div>
                  {/* <div className="d-flex align-item-center col-6">
                    <button className="button-one" onClick={handleQuiz}>Question Paper</button>
                  </div> */}
                  <div className="d-flex align-item-center col-6">
                    <button className="button-two" onClick={() => {  handleSubmit(); }}>Submit</button>
                  </div>
                </div>
              </div>
 
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExamPage