import React, { lazy, Suspense } from "react";

import './NoticeBoardComponent.css'
import { useEffect } from "react";
const NoticeBoard = lazy(() => import("./NoticeBoard.jsx"));


const NoticeBoardsComponent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
          <NoticeBoard/>
      </Suspense>

    </>
  );
}
export default NoticeBoardsComponent;
