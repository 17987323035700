import React, { useState, useEffect, useCallback } from "react";

import Logo from '../../asset/images/digital-edu-park-logo.webp';
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { categoriesClassReducer } from "../class/SubjectClassSlice";
import { debounce } from 'lodash';
import { SERVER_URL } from '../../config';
const BASE_URL = SERVER_URL;
// import { useDispatch, useSelector } from "react-redux";
const Navbar = () => {

	const [lastScrollTop, setLastScrollTop] = useState(0);
	const [scrollDirection, setScrollDirection] = useState(null);
	const [isLogin, setIsLogin] = useState(false);
	useEffect(() => {
		const handleScroll = () => {
			const scroll_top = window.pageYOffset;
			if (scroll_top < lastScrollTop) {
				setScrollDirection('up');
			} else {
				setScrollDirection('down');
			}
			if (scroll_top === 0) {
				setScrollDirection(null);
			}
			setLastScrollTop(scroll_top);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [lastScrollTop]);


	const navigate = useNavigate();

	const initialUser = localStorage.getItem("token") || null;
	const initialUserName = localStorage.getItem("user_name") || null;
	const [user, setUser] = useState(initialUser !== "null" ? initialUser : null);
	const [userName, setUserName] = useState(initialUserName);

	useEffect(() => {

		const token = localStorage.getItem("token");
		const UserName = localStorage.getItem("user_name");
		if (token) {
			setIsLogin(true);
		}
		setUserName(UserName || null);
		setUser(token || null);
	}, [initialUser]);


	const logout = () => {
		setIsLogin(false);
		const token_data = localStorage.removeItem("token");
		localStorage.removeItem("user_name");

		setUser(null);

		navigate("/");
		window.location.href = '/';
	};


	const [previewUrl, setPreviewUrl] = useState(null);
	const [profilePicName, setProfilePicName] = useState(null);
	const [error, setError] = useState('');
  
	// useEffect(() => {
	//   const token = localStorage.getItem('token');
	//   if (!token) {
	// 	setError('User is not authenticated');
	// 	return;
	//   }
  
	//   axios.get(`${BASE_URL}/student-details`, {
	// 	headers: {
	// 	  'remember-token': token,
	// 	}
	//   })
	// 	.then(response => {
  
	// 	  if (response.data.status) {
  
	// 		setPreviewUrl(response.data.data.profile);
  
	// 		let profile = response.data.data.profile;
	// 		let imageName = profile.split('/').pop();
	// 		setProfilePicName(imageName);
	// 		setError(''); // Clear any previous errors
	// 	   }
	// 	})
	// 	.catch(error => {
	// 	  console.error('There was an error fetching the data!', error);
	// 	  setError('No Data Found.');
	// 	});
	// }, []);

	const routerpath = useLocation();


	
	// handle 429 too many request issue by using Request Queue
	class RequestQueue {
		constructor() {
			this.queue = [];
			this.isProcessing = false;
		}

		enqueue(requestFunction) {
			this.queue.push(requestFunction);
			this.processQueue();
		}

		async processQueue() {
			if (this.isProcessing) return;

			this.isProcessing = true;

			while (this.queue.length > 0) {
				const requestFunction = this.queue.shift();
				await requestFunction();
			}

			this.isProcessing = false;
		}
	}

	const requestQueue = new RequestQueue();

	const dispatch = useDispatch()

	const categoryData = useSelector((state) => state.subjectClass.classCategories) ;
	// let [categoryData, setCategoryData] = useState([]);
	let [userProfile, setUserProfile] = useState('');

	let stateResponse = useSelector((state) => state)


	const getStudentDetails = async () => {
		return new Promise((resolve, reject) => {
			requestQueue.enqueue(async () => {
				try {
					const token = await localStorage.getItem("token");
	
					const response = await axios.get(`${BASE_URL}/student-details`, {
						headers: {
							"remember-token": token,
						},
					});
	
					setUserProfile(response?.data?.data);
					resolve(response?.data);
	
					if (response.data.status) {
						setPreviewUrl(response.data.data.profile);
						let profile = response.data.data.profile;
						let imageName = profile.split('/').pop();
						setProfilePicName(imageName);
						setError('');
					}
				} catch (error) {
					if (error.response?.status === 429) {
						// console.warn(`429 Too Many Requests. Retrying in ${} seconds...`);
						setTimeout(() => {
							getStudentDetails().then(resolve).catch(reject);
						},300);
					} else {
						reject(new Error(error.response?.data?.message || error.message));
					}
				}
			});
		});
	};
	

	const getClassCategoriesList = useCallback(async () => {
		return new Promise((resolve, reject) => {
			requestQueue.enqueue(async () => {
				try {
					dispatch(categoriesClassReducer());
					resolve();
				} catch (error) {
					if (error.response?.status === 429) {
						console.warn('429 Too Many Requests. Retrying in 1 second...');
						setTimeout(() => {
							getClassCategoriesList().then(resolve).catch(reject);
						}, 100);
					} else {
						reject(new Error(error.response?.data?.message || error.message));
					}
				}
			});
		});
	}, []);
	


	useEffect(() => {
		getStudentDetails();
		// let { subjectClass } = stateResponse
		// let { classCategories } = subjectClass


		// if (classCategories.length === 0) {
		// 	getClassCategoriesList();
		// } else {
		// 	setCategoryData(classCategories)
		// }
		
	}, [])

	
	const debouncedGetAllQuestionList = useCallback(debounce(() => {
		getClassCategoriesList();
		// getStudentDetails();
	  }, 300), [getClassCategoriesList]);
	
	  useEffect(() => {
		debouncedGetAllQuestionList();
		return debouncedGetAllQuestionList.cancel;
	  }, [debouncedGetAllQuestionList]);


	const createSlug = (name) => {
		return name.toLowerCase().replace(/\s+/g, '-'); // Converts spaces to hyphens
	};

	return (
		<nav className={`navbar navbar-expand-xl smart-scroll ${scrollDirection ? `scrolled-${scrollDirection}` : ''} ${routerpath.pathname === '/blog' ? 'dark__menu' : ''}`} id="mainNav">
			<div className="container-fluid position-relative px-sm-4 px-md-5">

				<Link to="/" className="navbar-brand p-0 m-0 d-inline-block" aria-label="Digitaledupark" title="Digitaledupark">
					<img src={Logo} width="180" height="136" className="w-auto" alt="Digitaledupark" />
				</Link>

				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ms-auto">
						<li className="nav-item ms-lg-2 ms-xxl-3">
							<Link className="nav-link active" aria-current="page" to="/">Home</Link>

						</li>

						<li className="nav-item ms-lg-2 ms-xxl-3">
							<Link className="nav-link" to="/about">About Us</Link>
						</li>

						{categoryData.map((data, index) => {
							// Check if userProfile and userProfile.class_id are defined
							const userClassId = userProfile?.class_id;

							// Check if there is at least one matching class_id or if userProfile.class_id is not defined
							const hasMatchingClass = !userClassId || data.class.some(classData => classData?.class_id === userClassId);

							// If no matching class_id and userProfile.class_id is defined, return null to skip rendering this category
							if (!hasMatchingClass) return null;

							return (
						<li className="nav-item ms-lg-2 ms-xxl-3 dropdown" key={index}>
							<Link className="nav-link dropdown-toggl" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							{data.category}
							</Link>
							 
							<div className="dropdown-menu classes">
								<ul className="row row-cols-2 g-0"> 
									{data.class.map((classData, classIndex) => (
										(!userClassId || classData?.class_id === userClassId) && (
											<li key={classIndex}>
												{/* <Link 
													className="dropdown-item"
													// to={`/classes/${classData?.class_id}`}
												>
													{classData.class_name}
												</Link> */}

												<div
													className="dropdown-item"
													onClick={() => {
														navigate(`/classes/${createSlug(classData?.class_name)}`, {
															state: { classId: classData?.class_id },
															replace: true,
														});
														window.location.reload();
													}}
													style={{ cursor: 'pointer' }} // Optional: indicates it's clickable
												>
													{classData.class_name}
												</div>
											</li>
										)
									))}
								</ul>
								{/* <li><Link to="#" className="dropdown-item" >action</Link></li>
								<li><Link to="#" className="dropdown-item" >Another action</Link></li> */}
							</div>
						</li>
							);
						})}

						{/* <li className="nav-item ms-lg-2 ms-xxl-3">
							<Link to="#" className="nav-link">Govt Services</Link>
						</li>
						<li className="nav-item ms-lg-2 ms-xxl-3">
							<Link to="#" className="nav-link">Competitive Exams</Link>
						</li>
						<li className="nav-item ms-lg-2 ms-xxl-3">
							<Link to="#" className="nav-link">Rewards</Link>
						</li> */}
						{isLogin && (
							<li className="nav-item ms-lg-2 ms-xxl-3">
								<Link to="/admin-dashboard" className="nav-link">Dashboard</Link>
							</li>
						)}
					</ul>


				</div>
				{/* <div className="d-inline-flex gap-3">
						{user ? ( 
							<>
							<Link to="#" className="btn btn-primary ff-pro fw-semibold text-uppercase rounded-0 py-2 ms-4" onClick={logout} >Logout</Link>
						<button className="navbar-toggler p-0 border-0 d-inline-flex justify-content-center align-items-center d-xl-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24"><path fill="none" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/></svg>
						</button>
							</>
						
						   ) : ( 
							<>
						   <Link to="#" className="btn btn-primary ff-pro fw-semibold text-uppercase rounded-0 py-2 ms-4" aria-label="Login" data-bs-toggle="modal" data-bs-target="#loginPopup">Login</Link>
						   <button className="navbar-toggler p-0 border-0 d-inline-flex justify-content-center align-items-center d-xl-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							   <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24"><path fill="none" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/></svg>
						   </button>
						   </>
							  ) 
							}
					</div> */}
			<div className="d-inline-flex gap-3 ms-3">
				{user ? (
					<>
						<div className={`dropdown`}>
							<button className={`btn btn-white rounded-pill gap-2 p-2 border-0 shadow-none user__profile__btn`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
								{previewUrl ? (
									<>
									<img className="rounded-circle" src={previewUrl} width={35}
									height={35} alt="Ryan Taylor" />
									</>
								):(
									<>
									<svg
									xmlns="http://www.w3.org/2000/svg"
									width={29}
									height={28}
									fill="none"
								>
									<path
										fill="#656F8B"
										d="M14.442 16.132c-3.272 0-5.925-2.612-5.925-5.834 0-3.221 2.653-5.833 5.925-5.833s5.924 2.612 5.924 5.834c0 3.221-2.652 5.833-5.924 5.833Z"
									/>
									<path
										fill="#656F8B"
										d="M14.441 0c7.846 0 14.22 6.26 14.22 14 0 7.724-6.358 14-14.22 14C6.58 28 .223 21.74.223 14c0-7.724 6.374-14 14.22-14ZM4.864 22.714v-.427c0-2.72 2.247-4.94 5.018-4.94h9.226c2.762 0 5.017 2.213 5.017 4.94v.346A12.683 12.683 0 0 0 27.51 14c0-7.089-5.843-12.841-13.043-12.841-7.2 0-13.05 5.752-13.05 12.841a12.52 12.52 0 0 0 3.448 8.714Z"
									/>
								</svg>
								</>
								)}
								<span className="d-none d-sm-block">{user ? <> {userName ?? ""} </> : <> {""}</>} </span>
							</button>
							<ul className={`dropdown-menu user__drop__menu`}>
								<li><Link to="/admin-dashboard" className="dropdown-item">My Dashboard</Link></li>
								<li><Link to="/profile" className="dropdown-item">My Profile</Link></li>
								<li><Link to="#" className="dropdown-item" onClick={logout} >Logout</Link></li>
							</ul>
						</div>
					</>

				) : (
					<>
						<Link to="#" className="btn btn-primary ff-pro fw-semibold text-uppercase rounded-0 py-2 ms-lg-4" aria-label="Login" data-bs-toggle="modal" data-bs-target="#loginPopup">Login</Link>
					</>
				)
				}
				<button className="navbar-toggler p-0 border-0 d-inline-flex justify-content-center align-items-center d-xl-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24"><path fill="none" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" /></svg>
				</button>
			</div>
			</div>
		 
		</nav >

	);
};
export default Navbar;