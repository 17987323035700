import './App.css';
import Home from './components/layouts/Main.jsx';
import About from './components/About.jsx';
import ClassComponent from './components/ClassComponent.jsx';
import { BrowserRouter as Router, Routes, Route, createBrowserRouter, RouterProvider } from "react-router-dom";
import ClassHeader from './components/class/ClassHeader.jsx';
import AdminDashboard from "./admin/dashboard/Dashboard.jsx"
import Instruction from "./admin/Instruction/instruction.jsx";
import Instruction1 from './admin/Instruction/Instruction1.jsx';
import Exampage from './admin/exam/Exampage.jsx';
import Result from './admin/exam/Result.jsx';
import ResultGraph from './admin/exam/resultGraph.jsx';
import ExamQuestion from './admin/exam/ExamQuestion.jsx';
import FAQs from './components/FAQs/FAQs.jsx';
import QuizPage from './admin/quizz/QuizPage.jsx';
import UserProfile from './admin/quizz/UserProfile.jsx';
import Exam from './admin/exam/Exam.jsx';
import SingleQuizDetails from './admin/quizz/SingleQuizDetails.jsx';
import AdminLayout from './admin/layouts/AdminLayout.jsx';
import ExamLayout from './admin/layouts/ExamLayout.jsx';
import QuizDetails from './admin/quizz/quiz-details.jsx';
import Blog from './components/Blogs/Blog.jsx';
import Layout from './components/layouts/Layout.jsx';
import NoticeBoardsComponent from './components/NoticeBoard/NoticeBoardsComponent.jsx';
import StudentNoticeBoard from './admin/StudentNoticeBoards/StudentNoticeBoard.jsx';

function App() {
    const router = createBrowserRouter([ 
        {
            path: "/",
            element: <Layout />,
            children: [
                { path: "/", element: <Home /> },
                { path: "/about", element: <About /> },
                { path: "/faqs", element: <FAQs /> },
                // { path: "/classes/:id", element: <ClassComponent /> },
                { path: "/classes/:className", element: <ClassComponent /> },
                { path: "/classHeader", element: <ClassHeader /> },
                { path: "/blog", element: <Blog /> },
                { path: "/notice-board", element: <NoticeBoardsComponent /> },
            ],
        }, 
        {
            path: "/",
            element: <AdminLayout />,
            children: [
                { path: "/instruction", element: <Instruction /> },
                { path: "/instruction-details", element: <Instruction1 /> }, 
                { path: "/admin-dashboard", element: <AdminDashboard /> },
                { path: "/quiz", element: <QuizPage />  },
                { path: "/quize-details/:id", element: <QuizDetails />  },
                { path: "/singleQuiz", element: <SingleQuizDetails /> },
                { path: "/profile", element: <UserProfile /> }, 
                { path: "/student-notice-board", element: <StudentNoticeBoard /> }, 
            ],
        }, 
        {
            path: "/",
            element: <ExamLayout />,
            children: [ 
                { path: "/exam", element: <Exam /> },
                { path: "/result", element: <Result /> },
            ],
        }, 
    ]);

    return (
        <>
            <RouterProvider router={router} /> 
        
        </>
    )
}

export default App;