import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import './StudentNoticeBoard.css';
import axios from 'axios';
import { SERVER_URL } from "../../config";
const BASE_URL = SERVER_URL;

const itemsPerPage = 10;

function StudentNoticeBoard() {
    const [currentPage, setCurrentPage] = useState(0);
    let [notices, setNotices] = useState([]);
    const [error, setError] = useState('');

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };
    // handle 429 too many request issue by using Request Queue
    class RequestQueue {
        constructor() {
            this.queue = [];
            this.isProcessing = false;
        }

        enqueue(requestFunction) {
            this.queue.push(requestFunction);
            this.processQueue();
        }

        async processQueue() {
            if (this.isProcessing) return;

            this.isProcessing = true;

            while (this.queue.length > 0) {
                const requestFunction = this.queue.shift();
                await requestFunction();
            }

            this.isProcessing = false;
        }
    }

    const requestQueue = new RequestQueue();

    const getNoticeData = async () => {
        return new Promise((resolve, reject) => {
            requestQueue.enqueue(async () => {
                try {
                    const token = await localStorage.getItem('token');
                    const response = await axios.get(`${BASE_URL}/notice-board`, {
                        headers: {
                            'remember-token': token,
                        }
                    });

                    setNotices(response.data.data);

                    resolve(response.data);
                } catch (error) {
                    if (error.response?.status === 429) {
                        console.warn('429 Too Many Requests. Retrying in 1 second...');
                        setTimeout(() => {
                            getNoticeData().then(resolve).catch(reject);
                        }, 1000);
                    } else {
                        reject(new Error(error.response?.data?.message || error.message));
                    }
                }
            });
        });
    };


    useEffect(() => {
        getNoticeData();
    }, []);

    const offset = currentPage * itemsPerPage;
    const currentNotices = notices.slice(offset, offset + itemsPerPage);

    return (
        <div className="notice-board-container position-relative z-3">
            <h4>Student Notice Board</h4>
            <ul className="notice-list">
                {
                    (currentNotices && currentNotices.length > 0) ? (
                        currentNotices.map((notice, index) => {
                            // Format the date dynamically
                            const formattedDate = new Date(notice.notice_date).toLocaleDateString("en-GB", {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric'
                            });

                            return (
                                <li key={index} className="notice-item">
                                    {/* Format and display the notice date */}
                                    <div className="notice-date" dangerouslySetInnerHTML={{ __html: formattedDate }}></div>
                                    {/* Display the title of the notice */}
                                    <div className="notice-title" dangerouslySetInnerHTML={{ __html: notice.title }}></div>
                                    {/* Display the description of the notice */}
                                    <div className="notice-content" dangerouslySetInnerHTML={{ __html: notice.description }}></div>
                                </li>
                            );
                        })
                    ) : (
                        <li className="notice-item">No Notice available</li>
                    )
                }


            </ul>
            <div className="mt-3">
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(notices.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    );
}

export default StudentNoticeBoard;
